<template>
  <div>
    <v-row class="mx-0 my-0">
      <v-col v-for="(menu, index) in promoItemList" :key="index" cols="6" sm="6" md="6" class="px-1 py-1 mb-1">
        <div class="item-wrap d-flex flex-column flex-sm-row px-2 py-2" @click="clicked(menu)">
          <div class="item-left text-center w-30" :class="{ 'w-100': isMobileView }">
            <img class="item-img" :src="getItemImg(menu.images[0].url)" alt="item" />
          </div>
          <div class="item-right flex-grow-1 d-flex flex-column justify-sm-center align-center align-sm-start ml-0 ml-sm-4">
            <div class="item-label text-center mb-2">{{ menu.name }}</div>
            <!-- <div v-if="!isUndefinedNullOrEmpty(menu.price)" class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(menu.price)}` }}</div> -->
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { MENU_GRP } from '@/common/enum/menu';
export default {
  data() {
    return {
      promoItemList: [],
      promotionItemMenuId: 9,
      defaultImg: require('@/assets/logo.png'),
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    MENU_GRP() {
      return MENU_GRP;
    },
  },
  created() {
    this.SET_DRAWER_TITLE('Promotion');
    this.promoItemList = this.menuItemList
      .filter(item => item.menuId == this.promotionItemMenuId)
      .map(item => {
        const menuList = this.menuList.find(menu => menu.id == item.setId);
        return {
          ...item,
          grp: menuList.grp,
        };
      });
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    clicked(menu) {
      switch (menu.grp) {
        case MENU_GRP.SET:
          this.navigatePush('MenuSetItem', { menuId: menu.setId, promoItemId: menu.recId }, { storeId: this.$route.query.storeId });
          break;
        default:
          break;
      }
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.item-wrap {
  // border: 4px solid #000;
  background-color: #fff;
  border-radius: 10px;
  // box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  box-shadow: 1px 1px 3px 1px #8e8e8e;
  cursor: pointer;
  height: 100%;
}
.item-img {
  max-width: 100px;
  width: 100%;
  @include for-sm {
    max-width: 100px;
  }
}
.item-label {
  color: var(--primary-black-color);
  font-size: 1.25rem;
  font-weight: 600;
}
.item-price {
  color: var(--red-common);
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
